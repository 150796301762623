import React, {useState, useEffect, useRef} from 'react';
import Slider from "react-slick";
import Axios from 'axios';

import {CurrencyFormat} from '../../utils';

import VersionCard from '../components/VersionCard';

const VersionSelector = ({modelId, title, linkData, linkCotizador}) => {

    const [versions, setVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasWarnings, setHasWarnings] = useState(false);

    const slider = useRef(null);

    const settings = {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    variableWidth: false,
                }
            }
        ]
    };

    useEffect(() => {
        Axios.get(`/api/vehicles/versions_by_model/${modelId}`)
        .then(response => {
            setSelectedVersion(response.data[0])
            setVersions(response.data);
            response.data.forEach(version => {
                if (version.iva_warning || version.stock_warning) {
                    setHasWarnings(true);
                }
            })
        })
        .catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (selectedVersion) changeInfoBreacrumb();
    }, [selectedVersion]);

    const changeInfoBreacrumb = () => {
        document.getElementById('model-and-version').innerHTML = `${selectedVersion.model} ${selectedVersion.name}`;
        document.getElementById('price').innerHTML = `${CurrencyFormat(selectedVersion.price)}`;
        document.getElementById('green-tax').innerHTML = `+I.V. ${CurrencyFormat(selectedVersion.green_tax)}`;
        if (selectedVersion.link_to_bnp === true) {
            document.getElementById('button-cotizar').innerHTML = `Cotiza tu ${selectedVersion.model}`;
            document.getElementById('button-cotizar').href = `${linkCotizador}configurar/${selectedVersion.id}`;
            document.getElementById('button-cotizar').style.display = 'block';
            document.getElementById('button-cotizar').addEventListener('click', () => {
                if (dataLayer) {
                    dataLayer.push({
                        'event': 'trackEvent',
                        'eventCategory': 'Cotizador',
                        'eventAction': 'cta',
                        'eventLabel': `Cotiza tu ${selectedVersion.model}`
                    });
                }
            })
        } else {
            document.getElementById('button-cotizar').style.display = 'none';
        }
        
        document.getElementById('model-and-price').classList.add('active');
    }

    const handleClickVersion = version => {
        if(version.stock_warning) return;
        setSelectedVersion(version);
    }

    return (
        <>
            {!loading ?
            <>
                <div className="main-container title-container">
                    <h1>{ title }</h1>
                    {linkData.visible == "True" ? 
                        <a href={ linkData.page_url ? linkData.page_url : linkData.url } target={ linkData.opens_in } className="btn outline-black">{ linkData.title }</a>
                    : null}
                    <h2>Versión {selectedVersion && selectedVersion.name}</h2>
                </div>
                <div className="full-viewport-width">
                    { selectedVersion &&
                        <div className="main-container image-container">
                            <img src={selectedVersion.image.url} alt={selectedVersion.image.alt} />
                        </div>
                    }
                    <div className="version-cards-container">
                        <div className="main-container">
                            <h2>Elige tu versión</h2>
                            {versions.length &&
                                <Slider ref={slider} {...settings}>
                                    {versions.map(version => (
                                        <VersionCard key={`version-${version.id}`} version={version} selectedVersionId={selectedVersion.id} onClickVersion={handleClickVersion} hasWarnings={hasWarnings}/>
                                    ))}
                                </Slider>
                            }
                        </div>
                    </div>
                </div>
            </>
            : 
            <div className="spinner-container">
                <div className="spinner"></div>
            </div>
            }
        </>
    );
}

export default VersionSelector;
