import React from 'react';

import { CurrencyFormat } from '../../utils'

const VersionCard = ({ version, selectedVersionId, onClickVersion, hasWarnings }) => {

    const hasBonus = version.brand_bonus || version.financing_bonus || version.smart_buy_bonus

    return (
        <div className={"card-version " + (selectedVersionId === version.id ? 'active ' : '') + (version.stock_warning ? "stock-warning" : '')} onClick={() => onClickVersion(version)}>
            <div className="card-body">
                <div className="card-header">
                    <span className={"out-of-stock-message " + (version.stock_warning ? "show" : "")}>Temporalmente sin stock</span>
                    <div className="card-header-content">
                        <div>
                            <h3>{version.model} {version.name}</h3>
                            {hasWarnings ? (
                                <div className="warning_container">
                                    {version.iva_warning ? (<p className="version_warning">Precio incluye IVA</p>) : null}
                                </div>
                            ) : null}

                        </div>
                        <p>
                            <small>{CurrencyFormat(version.price)}<sup>(*)</sup></small>
                            <small className='green-tax'>+I.V. {CurrencyFormat(version.green_tax)}</small>
                        </p>
                    </div>
                </div>
                {hasBonus &&
                    <div className="bonus">
                        <img src={`${window._env_.STATIC_HOST}/static/website/images/icons/bonus.svg`} />
                        <div>
                            <p>Nuestro precio incluye:</p>
                            <ul>
                                {version.brand_bonus && <li>Bono <strong>marca</strong> de {CurrencyFormat(version.brand_bonus)}.</li>}
                                {version.financing_bonus && <li>Bono <strong>financiamiento MAF</strong> de {CurrencyFormat(version.financing_bonus)}.</li>}
                                {version.smart_buy_bonus && <li>Bono <strong>financiamiento Plan Renueve</strong> de {CurrencyFormat(version.smart_buy_bonus)}.</li>}
                            </ul>
                        </div>
                    </div>
                }
                <ul>
                    {version.main_features && version.main_features.map((feature, i) => (
                        <li key={`feature-${version.id}-${feature.name}-${i}`}>
                            <span>{feature.name} - </span>
                            <strong>{feature.description}</strong>
                        </li>
                    ))}
                </ul>

                <small className='card-disclaimer'>* Precio aplica para Régimen General y no para zona Franca. IVA incluido</small>
            </div>
        </div>
    );
}

export default VersionCard;