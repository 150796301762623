import React from "react";
import ReactDOM from "react-dom";
import VersionSelector from './views/VersionSelector';

const rootElement = document.getElementById("version-selector-root");
if (rootElement) {
    const modelId = rootElement.dataset.model;
    const title = rootElement.dataset.title;
    const linkData =  {
        visible: rootElement.dataset.ctavisible,
        title: rootElement.dataset.ctatitle,
        url: rootElement.dataset.ctaurl,
        opens_in: rootElement.dataset.ctaopens_in
    }
    const linkCotizador = rootElement.dataset.bnp_page_url
    ReactDOM.render(<VersionSelector modelId={modelId} title={title} linkData={linkData} linkCotizador={linkCotizador}/>, rootElement);
}